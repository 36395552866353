import React from "react";
import _ from "lodash";
import axios from "axios";

import EditIcon from '@material-ui/icons/Edit'

import TableCrud from "../TableCrud";
import { MediaCostUpdatePopup } from "./MediaCostUpdatePopup";
import Loading from "../Miscellaneous/Loading";

import { getMonth } from "../../../scripts/global";


import { AssetsPopup } from "./AssetsPopup";

import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination, MenuItem, Input, Button, Menu, Checkbox, TableSortLabel, Typography } from "@material-ui/core";
import moment from 'moment';
import TableConfig from './MediaBuysConfig';
import MediaBuysFilters from "./MediaBuysFilters";


const hiddenColsDefault = [
  'vendor_budget',
  'variable_cpl',
  'send_leads_to_email_system',
  'add_incoming_leads',
  'workflow_id',
  'campaign_name',
  'interest_name',
  'medium',
  'type_id',
  'content_name',
  'keyword',
  'publisher_name',
  'affiliate_name',
  'guru_name',
  'vendor_budget',
  'projected_margin',
  'client_marketing_costs',
  'client_campaign',
  'realized_margin'
];


class MediaSpent extends React.Component {
  constructor(props) {
    super(props);

    const now = new Date();
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

    this.state = {
      data: false,
      limit: 30,
      page: 0,
      activeRow: {},
      showUpdatePopup: false,


      defaultHiddenColumns: [...hiddenColsDefault],
      columnsAnchorEl: null,
      showAssetsPopup: false,
      loading: true,
      filterParams: {
        months: [months[now.getMonth()]], 
        years: [now.getFullYear().toString()],
        status: ["approved", "approved_paused"]
      },
      allColumns: (process.env.ORGANIZATION === 'Traders Agency') ? TableConfig.TA.cols : TableConfig.LM.cols,
      pagination: {
        size: 25,
        page: 0,
        totalCount: 0
      },
      sort: {
        asc: false,
        column: 'year'
      }
    };
    this.updateTable = this.updateTable.bind(this);

    this.toggleColsSettings = this.toggleColsSettings.bind(this);
  }

  async componentDidMount() {

    await this.loadData()

    // // load db
    // const { data } = await axios.get(
    //   process.env.SERVER_ENDPOINT + "/mediaBuysSpend"
    // );

    // // filter variable CPL
    // const media_buys = _.filter(data.media_buys, itm => itm.variable_cpl == 1)
    //   .sort((a, b) => b.media_buy_id - a.media_buy_id)

    // this.setState({ data: media_buys });
  }

  async loadData() {
    const serialize = (obj, prefix) => {
      var k, p, str, v;
      str = [];
      for (p in obj) {
        v = obj[p];
        k = prefix ? prefix + "[" + p + "]" : p;
        if (typeof v === "object") {
          str.push(serialize(v, k));
        } else {
          if (typeof (v) !== 'undefined' && v !== null && v.toString().length > 0) {
            str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
          }
        }
      }

      return str.join("&");
    };
    this.setState({
      ...this.state,
      loading: true
    })

    const url = process.env.SERVER_ENDPOINT + `/mediaBuysSpend?${serialize(this.state.filterParams)}&page=${this.state.pagination.page + 1}&size=${this.state.pagination.size}&orderBy=${this.state.sort.column}&orderDir=${this.state.sort.asc ? 'asc' : 'desc'}`

    const { data } = await axios.get(url);
    const media_buys = _.map(data.data, itm => ({
      ...itm,
      margin: itm.vendor_budget - itm.lm_cost
    }))
    data.meta.page--;

    let hiddenCols = Object.assign([], this.state.defaultHiddenColumns);
    let hiddenColumnsConfig = localStorage.getItem(`hiddenColumns${document.location.pathname}`);
    if (hiddenColumnsConfig !== null) {
      hiddenCols = JSON.parse(hiddenColumnsConfig);
    }

    this.setState({
      ...this.state,
      data: media_buys,
      pagination: data.meta,
      loading: false,
      defaultHiddenColumns: hiddenCols
    });
  }

  sortBy(col) {
    let direction = this.state.sort.asc;
    let column = this.state.sort.column;

    if (col === this.state.sort.column) {
      direction = !this.state.sort.asc;
    } else {
      column = col;
    }

    this.setState({
      ...this.state,
      sort: {
        column: column,
        asc: direction
      }
    }, this.loadData);
  }

  changeRowsCount(event, count) {
    let pagination = { ...this.state.pagination };
    pagination.page = 0;
    pagination.size = parseInt(count.key);
    this.setState({
      ...this.state,
      pagination: pagination
    }, this.loadData);
  }

  changePage(event, page) {
    let pagination = { ...this.state.pagination };
    pagination.page = page;

    this.setState({
      ...this.state,
      pagination: pagination
    }, () => {
      this.loadData();
    })
  }

  isColDisabled(col) {
    return this.state.defaultHiddenColumns.indexOf(col) > -1;
  }

  toggleColumn(col, value) {
    let defCols = [...this.state.defaultHiddenColumns];
    if (value) {
      defCols.splice(defCols.indexOf(col), 1);
    } else {
      defCols.unshift(col);
    }
    this.setState({
      ...this.state,
      defaultHiddenColumns: defCols
    });

    localStorage.setItem(`hiddenColumns${document.location.pathname}`, JSON.stringify(defCols));
  }

  switchColPreset(param) {
    let hiddenCols = [];
    if (param === 'default') {
      hiddenCols = [...hiddenColsDefault];
    } else if (param === 'none') {
      hiddenCols = this.state.allColumns.map(col => col.fieldName);
    }

    localStorage.setItem(`hiddenColumns${document.location.pathname}`, JSON.stringify(hiddenCols));

    this.setState({
      ...this.state,
      defaultHiddenColumns: hiddenCols
    })
  }

  toggleColsSettings(event) {
    this.setState({
      ...this.state,
      columnsAnchorEl: event.currentTarget,
      colsSettingsOpened: !this.state.colsSettingsOpened
    })
  }

  goToMediaBuy(itm) {
    window.location.href = `/media-planner/request?id=${itm.media_buy_id}`;
  }
  format(value, type) {
    if (!type) return value;
    switch (type) {
      case 'date':
        return moment(value).format('MM/DD/YYYY');

      case 'month':
        return moment().month(value.toString()).add('months', -1).format('MMMM');
      case 'money':
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value);
      default:
        return value;
    }
  }

  getAsset(data) {
    this.setState({
      showAssetsPopup: true,
      activeRow: data,
    })
  }

  handleSelect(params) {
    this.setState({
      ...this.state,
      filterParams: params
    }, this.loadData);
  }

  updateTable(changes) {
    if (!changes) {
      this.setState({ showUpdatePopup: false })
    } else {
      const data = [...this.state.data];
      const elem = { ...this.state.activeRow, ...changes }
      const idx = data.findIndex(v => v.media_buy_id == elem.media_buy_id)
      data[idx] = elem
      this.setState({ data, showUpdatePopup: false })
    }
  }

  render() {
    const { data } = this.state;

    const allHeaders = [
      {
        label: "Id",
        fieldName: "media_buy_id",
        minWidth: 500,
        onClick: itm =>
          (window.location.href = `/media-planner/request?id=${itm.media_buy_id}`)
      },
      {
        label: "Name",
        fieldName: "media_buy_name",
        minWidth: 500,
        onClick: itm =>
          (window.location.href = `/media-planner/request?id=${itm.media_buy_id}`)
      },
      {
        label: "Month",
        fieldName: "month",
        type: "filter",
        doFilter: str => getMonth(str)
      },
      { label: "Year", fieldName: "year" },
      { label: "Vendor", fieldName: "vendor", minWidth: 200 },
      { label: "Type", fieldName: "medium", minWidth: 200 },
      {
        label: "Client",
        fieldName: "client",
        minWidth: 200
      },
      ...(process.env.ORGANIZATION === 'LeadMark' ? [{
        label: "Internal Budget",
        fieldName: "lm_budget",
        type: "money"
        }] : []
      ),
      {
        label: process.env.ORGANIZATION === 'LeadMark' ? 'Current LeadMark Costs' : 'Current SA/TA Costs',
        fieldName: "lm_cost",
        type: "money"
      },
      { label: "Creator", fieldName: "name"},
      {
        label: "Edit cost",
        fieldName: "lm_cpl",
        default: (row) => <EditIcon onClick={ e =>  this.setState({ showUpdatePopup: true, activeRow: row })} />
      },

    ];

    const headers = _.filter(allHeaders, header => {
      const disabled_features = process.env.DISABLED_FEATURES.split(",");

      return header;
    });

    return (
      <>
        {!data ? (
          <Loading />
        ) : (
            <div>
              {this.state.showUpdatePopup && <MediaCostUpdatePopup
                data={this.state.activeRow}
                closePopup={this.updateTable}
              />}
              {this.state.showAssetsPopup && <AssetsPopup data={this.state.activeRow} closePopup={() => this.setState({ showAssetsPopup: false })} />}
              <Table className="reports__table media__planner" stickyHeader>
                <TableHead>
                  <TableRow className="reports__header-sticky">
                    <TableCell key={`col_title`} colSpan={headers.length}>
                      <div className="reports__header_container" style={{ minWidth: '930px' }}>
                        <h3>Media Spend</h3>

                        <MediaBuysFilters onSelect={params => this.handleSelect(params)} initFilter={this.state.filterParams} />
                        <button className="colsSettingsBtn" aria-haspopup="true" onClick={event => this.toggleColsSettings(event)}>
                          <img alt="" src="/icons/svg/settings.svg" />
                        </button>

                        <Menu
                          id="columns-menu"
                          keepMounted
                          anchorEl={this.state.columnsAnchorEl}
                          open={Boolean(this.state.colsSettingsOpened)}
                          onClose={this.toggleColsSettings.bind(this)}
                        >
                          <Typography style={{ height: '30px' }}>
                            <span style={{ fontSize: '14px', float: 'right' }}>
                              Select:
                                            <button onClick={() => this.switchColPreset('all')} className="filter_select-allnone">All</button>
                              <button onClick={() => this.switchColPreset('none')} className="filter_select-allnone">None</button>
                              <button onClick={() => this.switchColPreset('default')} className="filter_select-allnone">Default</button>
                            </span>
                          </Typography>

                          {headers.map((d, j) => d ? (
                            d.type !== 'template' ?
                              <MenuItem onClick={() => this.toggleColumn(d.fieldName, (this.state.defaultHiddenColumns.indexOf(d.fieldName) > -1))} key={`select_col_key_${j}`} value={d.fieldName}>
                                <Checkbox checked={!this.isColDisabled(d.fieldName)} value={d.fieldName} />
                                {d.label}
                              </MenuItem>
                              : null
                          ) : null)}
                        </Menu>
                        {!this.state.loading ?
                          <TablePagination
                            style={{
                              position: 'absolute',
                              right: '190px',
                              top: 0
                            }}
                            rowsPerPageOptions={[15, 25, 50, 100]}
                            component="div"
                            count={this.state.pagination.totalCount}
                            rowsPerPage={this.state.pagination.size}
                            page={this.state.pagination.page}
                            onChangePage={this.changePage.bind(this)}
                            onChangeRowsPerPage={this.changeRowsCount.bind(this)}
                          />
                          : null}
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {headers.map((col, i) => (
                      !this.isColDisabled(col.fieldName) && !this.state.loading ?
                        <TableCell className={`results__col_${i}`} key={`col_${i}`}>
                          <TableSortLabel
                            active={this.state.sort.column === col.fieldName}
                            direction={this.state.sort.asc ? 'asc' : 'desc'}
                            onClick={() => this.sortBy(col.fieldName)}
                          >
                            {col.label}
                          </TableSortLabel>
                        </TableCell>
                        : null
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!data || this.state.loading ? (
                    <TableRow style={{ backgroundColor: '#fff' }}>
                      <TableCell colSpan={10}>
                        <Loading />
                      </TableCell>
                    </TableRow>

                  ) : (
                      this.state.data.map((row, i) => (
                        <TableRow key={i}>
                          {headers.map((col, j) => (
                            !this.isColDisabled(col.fieldName) ?
                              <TableCell
                                onClick={col.onClick ? () => col.onClick(row) : null}
                                style={{ textDecoration: col.onClick ? 'underline' : 'none', cursor: col.onClick ? 'pointer' : 'inherit' }}
                                key={`_col_row_${j}_${i}`}>
                                {col.default ? col.default(row) :
                                  typeof row[col.fieldName] !== 'undefined'  && row[col.fieldName] !== null && row[col.fieldName] !== '' ?
                                    this.format(row[col.fieldName], col.type) : '-'}
                              </TableCell>
                              : null
                          ))}
                        </TableRow>
                      ))
                    )}
                </TableBody>
              </Table>
            </div>
          )}
      </>
    );
  }
}

export default MediaSpent;
